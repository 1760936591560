import React from 'react';
import FilterPageMenu from './FilterPageMenu';
import FilterPageFilters from './FilterPageFilters';
import FilterPageContent from './FilterPageContent';

class FilterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showFilters: false,
        }
    }

    toggleFilterMenu = () => {
        this.setState({ showFilters: (this.state.showFilters ? false : true) })
    }

    render() {
        return (
            <div className={"filter-page" + (this.state.showFilters ? ' show-filters' : '' )}>
                <FilterPageMenu user={this.props.user} onMenuOpen={this.props.onMenuOpen} toggleFilterMenu={this.toggleFilterMenu} />

                {this.props.dataEnabled === false &&
                    <div className="filter-page-content">
                        <div className="filter-page-content__content">
                            <h4>The data functionality is currently unavailable. Please try again later.</h4>
                        </div>
                    </div>
                }

                {this.props.dataEnabled === true &&
                    <>
                        <FilterPageFilters
                            onCriteriaChange={this.props.onCriteriaChange} 
                            searchInputRef={this.props.searchInputRef}
                            criteria={this.props.criteria}
                            onProfileClick={this.props.onProfileClick}
                            isResourceIndex={this.props.isResourceIndex}
                            isDataIndex={this.props.isDataIndex}
                            isNetworkIndex={this.props.isNetworkIndex}
                            selectableIds={this.props.selectableIds}
                            showDateFilter={this.props.showDateFilter}
                            setStartDate={this.props.setStartDate}
                            setEndDate={this.props.setEndDate}
                            onDateSubmit={this.props.onDateSubmit}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            titleSearchTitle={this.props.titleSearchTitle}
                            user={this.props.user}
                            searchType={this.props.searchType}
                            additionalProfileSearch={this.props.additionalProfileSearch}
                            advertBanner={this.props.advertBanner}
                        />
                        <FilterPageContent 
                            pageTitle={this.props.pageTitle} 
                            categories={this.props.categories} 
                            onCategoryChange={this.props.onCategoryChange}
                            pageContent={this.props.pageContent}
                            preFilterContent={this.props.preFilterContent}
                            criteria={this.props.criteria}
                            removeCriteria={this.props.removeCriteria}
                            clearCriteria={this.props.clearCriteria}
                            basket={this.props.basket}
                            basketModalToggle={this.props.basketModalToggle}
                            scrollRef={this.props.scrollRef}
                            resultCount={this.props.resultCount}
                            exportUrl={this.props.exportUrl}
                            showHeatMap={this.props.showHeatMap}
                            heatMapStatus={this.props.heatMapStatus}
                            changeHeatMapType={this.props.changeHeatMapType}
                            loading={this.props.loading}
                            favouritesLink={this.props.favouritesLink}
                            uploadCta={this.props.uploadCta}
                            uploadCtaOnClick={this.props.uploadCtaOnClick}
                            showIntro={this.props.showIntro}
                            uploadResourceOnClick={this.props.uploadResourceOnClick}
                        />
                    </>
                }
            </div>
        );
    }
}

export default FilterPage;