import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

class NetworkUserItem extends React.Component {
    render() {
        let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
        let user = this.props.user;
        let hasImage = user.file ? true : false;

        return (
            <Col key={`resource-${user.id}`} xl="3" md="4" sm="6" xs="12">
                <div
                    className={
                        "filter-page-user-item" + (hasImage ? " has-image" : "")
                    }
                >
                    <Link to={"/user/" + user.id}>
                        {hasImage === true && (
                            <div className="filter-page-user-item__image">
                                <img
                                    src={
                                        entrypoint +
                                        "/media/" +
                                        encodeURI(this.props.user.file.filePath)
                                    }
                                />
                            </div>
                        )}
                        <div className="filter-page-user-item__name">
                            {user.fullName}
                        </div>
                    </Link>
                </div>
            </Col>
        );
    }
}

export default NetworkUserItem;

