import React, { useEffect, useState, useRef } from "react";
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Input,
} from "@material-ui/core";
import axios from "axios";
import { faCloudUploadAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap";

export default function CreateResource() {
    const [resource, setResource] = useState({
        title: "",
        url: "",
        category: "",
        file: null,
    });

    const inputFileRef = useRef(null);
    const selectRef = useRef(null);

    const [error, setError] = useState("");
    const [categories, setCategories] = useState([]);
    const [success, setSuccess] = useState(false);

    const entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

    const fetchCategories = async () => {
        try {
            const response = await axios.get(
                `${entryPoint}/api/resource/category`,
                {
                    headers: {
                        authorization: `Bearer ${window.localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );

            const data = response?.data?.categories || [];
            setCategories(data);
        } catch (error) {
            console.error("Error fetching categories", error);
            setError("Failed to load categories.");
        }
    };

    const submitResource = async () => {
        const url = `${entryPoint}/api/resources/create`;
        const formdata = new FormData();
        formdata.append("title", resource.title);

        if (resource.url) formdata.append("url", resource.url);
        if (resource.file) formdata.append("file", resource.file);
        if (resource.category) formdata.append("category", resource.category);

        try {
            const response = await axios.post(url, formdata, {
                headers: {
                    authorization: `Bearer ${window.localStorage.getItem(
                        "token"
                    )}`,
                },
            });
            if (!response.data.success) {
                setSuccess(false);
                setError(response.data.message || "Unknown error occurred");
            } else {
                setError("");
                setSuccess(true);
                resetForm();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleError = (error) => {
        setSuccess(false);
        if (error.response) {
            setError(
                "Error adding resource: " +
                    (error.response.data.message || error.response.statusText)
            );
        } else if (error.request) {
            setError(
                "Error adding resource: No response received from server."
            );
        } else {
            setError("Error adding resource: " + error.message);
        }
    };

    const resetForm = () => {
        setResource({
            title: "",
            url: "",
            category: "",
            file: null,
        });
        inputFileRef.current.value = null;
        selectRef.current.value = "";
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(""); // Reset error state before submission
        setSuccess(false); // Ensure previous success message is cleared

        if (!resource.file && !resource.url) {
            setError("Please provide a file or URL.");
            return;
        }

        submitResource();
    };

    return (
        <>
             <p>
                Use the form below to upload a resource.
                Once approve by the GVH it will be available on the site.
                (Resources limited to PDF, DOC, or DOX - alternatively a URL can be entered instead of a file)
            </p>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <TextField
                        required
                        className="pb-3"
                        id="title"
                        label="Title"
                        placeholder="Enter the title of the resource"
                        value={resource.title}
                        onChange={(e) =>
                            setResource({ ...resource, title: e.target.value })
                        }
                        fullWidth
                        variant="filled"
                        margin="normal"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        className="pb-3"
                        id="url"
                        label="URL"
                        placeholder="Enter the URL of the resource"
                        value={resource.url}
                        onChange={(e) =>
                            setResource({ ...resource, url: e.target.value })
                        }
                        fullWidth
                        variant="filled"
                        margin="normal"
                    />
                </FormControl>

                {categories.length > 0 && (
                    <FormControl fullWidth>
                        <InputLabel id="resource-category-label">
                            Category
                        </InputLabel>
                        <Select
                            labelId="resource-category-label"
                            id="category"
                            value={resource.category}
                            onChange={(e) =>
                                setResource({
                                    ...resource,
                                    category: e.target.value,
                                })
                            }
                            ref={selectRef}
                            MenuProps={{
                                style: {
                                    zIndex: 9999, // Make sure the select dropdown appears above other elements
                                },
                            }}
                        >
                            {categories.map((cat) => (
                                <MenuItem key={cat.id} value={cat.title}>
                                    {cat.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <FormControl fullWidth>
                    <input
                        ref={inputFileRef}
                        id="resource-file-input"
                        type="file"
                        onChange={(e) =>
                            setResource({
                                ...resource,
                                file: e.target?.files[0],
                            })
                        }
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        className="py-4"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Button 
                        className="ml-auto mx-0 mt-5 mb-4 font-smooth text-white"
                        variant="ternary"
                        type="submit"
                    >
                        <>
                            <span className="mr-2"><FontAwesomeIcon icon={faCloudUploadAlt} /></span>
                            Submit
                        </>
                    </Button>
                </FormControl>
            </form>
            {error && <p className="text-danger">{error}</p>}
            {success && (
                <p className="text-success">Resource added successfully</p>
            )}
        </>
    );
}