import React from "react";

import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Row, Col, Tabs, Tab } from "react-bootstrap";

import Api from "../Api";
import SideBar from "../components/SideBar";
import Loading from "../components/Loading";
import ProfileList from "../components/ProfileList";
import ProfileUserContent from "../components/ProfileUserContent";
import Resources from "../components/Resources/Resources";
import Data from "../components/Data/Data";
import CreateAccountForm from "../components/CreateAccountForm";

class UserPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userProfile: false,
            userId: false,
            userFound: true,
            types: [],
            profilesByType: [],
            loading: true,
            profileExists: true,
        };
    }

    componentDidMount() {
        let userId = this.getUserId(this.props.location.pathname);

        if (!userId) {
            return;
        }

        this.loadUser(userId);
    }

    getUserId(pathname) {
        const pattern = /^\/user\/(.+)$/;
        const found = pattern.exec(pathname);

        if (!found) {
            return;
        }

        return found[1];
    }

    loadUser(userId) {
        if (userId.substring(userId.length - 1) === "/") {
            userId = userId.substring(0, userId.length - 1);
        }

        if (userId !== this.state.userId) {
            this.setState({ userId, loading: true });

            Api.users(true, true, userId)
                .then((user) => {
                    if (user) {
                        const profilesByType = {};
                        if (user.relationships) {
                            let query = {
                                resource_type: "profile",
                                relationship_ids: user.id,
                                and_join: "1",
                                include_users: "1",
                            };

                            return Api.search(query).then((relationships) => {
                                let profilesByType = [];

                                relationships.data.forEach((item, key) => {
                                    if (
                                        item.profileType &&
                                        !item.profileType.hideOnProfile
                                    ) {
                                        if (item.firstName) {
                                            if (!profilesByType[0]) {
                                                profilesByType[0] = {
                                                    title: "Users",
                                                    profiles: [],
                                                };
                                            }

                                            profilesByType[0].profiles.push(
                                                item
                                            );
                                        } else {
                                            if (
                                                !profilesByType[
                                                    item["profileType"].id
                                                ]
                                            ) {
                                                profilesByType[
                                                    item["profileType"].id
                                                ] = {
                                                    title: item["profileType"]
                                                        .title,
                                                    profiles: [],
                                                };
                                            }

                                            profilesByType[
                                                item["profileType"].id
                                            ].profiles.push(item);
                                        }
                                    }
                                });

                                return [user, profilesByType];
                            });
                        } else {
                            return [user, profilesByType];
                        }
                    } else {
                        this.setState({ userFound: false });
                    }
                })
                .then(([userProfile, profilesByType]) => {
                    userProfile.profileType = {};
                    userProfile.profileType.title = "User";

                    this.setState({
                        userProfile,
                        profilesByType,
                        loading: false,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    if (error.name === "AuthenticationError") {
                        this.props.history.push("/log-in");
                    }

                    this.setState({
                        userProfile: false,
                        userId: "",
                        types: [],
                        profilesByType: [],
                        loading: true,
                        userFound: false,
                    });
                });
        }
    }

    onProfileClose = () => {
        this.props.setDataClass(true);
        this.props.setDataIndexClass(true);
        this.props.history.goBack();
    };

    render() {
        if (this.state.userFound === false) {
            return (
                <SideBar page="404">
                    <h2>User not found.</h2>
                    <Link to="/">Return Home</Link>
                </SideBar>
            );
        }

        const profilesHtml = [];
        const networkHtml = [];

        if (this.state.profilesByType.length) {
            this.state.profilesByType.forEach((item, key) => {
                const component = (
                    <ProfileList
                        key={key}
                        typeTitle={item.title}
                        profiles={item.profiles}
                        profileType={
                            item.title === "Users" ? "user" : "profile"
                        }
                    />
                );

                if (
                    item.title === "Users" ||
                    item.title === "Collaborator" ||
                    item.title === "Institution"
                ) {
                    networkHtml.push(component);
                } else {
                    profilesHtml.push(component);
                }
            });
        }

        const loadingClass = this.state.loading ? "loading" : "";

        return (
            <div
                className={`main-column-content-inner profile-page-wrap ${loadingClass}`}
            >
                {this.state.loading === true && (
                    <div className="loading-loader">
                        <Loading size="6x" />
                    </div>
                )}

                <div className="profile-header bg-primary d-flex justify-content-between align-items-center p-4">
                    {this.state.userProfile && (
                        <h3 className="profile-title font-smooth">
                            {this.state.userProfile.fullName}
                        </h3>
                    )}
                    <Button
                        className="close-link font-smooth"
                        variant="link"
                        onClick={() => this.onProfileClose()}
                    >
                        Close
                    </Button>
                </div>

                <Tabs
                    className="profile-tabs d-flex justify-content-around pl-4"
                    defaultActiveKey="profile"
                    id="profile-tabs"
                >
                    <Tab eventKey="profile" title="Profile">
                        <Row className="no-gutters">
                            <div className="profile-sidebar">
                                <span className="sideways-text font-smooth">
                                    Background
                                </span>
                            </div>
                            <Col>
                                <div className="profile-inner">
                                    <ProfileUserContent
                                        user={this.state.userProfile}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {profilesHtml}
                    </Tab>
                    <Tab eventKey="resources" title="Resources">
                        <div className="d-flex flex-column m-5">
                            <Resources
                                profile={this.state.userProfile}
                                user={this.props.user}
                                setDataClass={this.props.setDataClass}
                                setDataIndexClass={this.props.setDataIndexClass}
                                setResourceTreeClass={
                                    this.props.setResourceTreeClass
                                }
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="data" title="Data">
                        <div className="d-flex flex-column h-100">
                            <div className="p-5">
                                <Data
                                    profile={this.state.userProfile}
                                    setDataClass={this.props.setDataClass}
                                    setDataIndexClass={
                                        this.props.setDataIndexClass
                                    }
                                    setResourceTreeClass={
                                        this.props.setResourceTreeClass
                                    }
                                />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="network" title="Network">
                        {networkHtml.length > 0 && networkHtml}

                        {networkHtml.length === 0 && (
                            <div className="p-5">
                                <p>No network information found.</p>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default withRouter(UserPage);

